





















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop() value!: string
  @Prop() items!: string[]
  @Prop({ default: 40 }) height!: number
  @Prop({ default: 200 }) width!: number
}
